<!--
 * @Author: linjituan linjituan@revolution.com
 * @Date: 2023-10-18 10:25:59
 * @LastEditors: linjituan linjituan@revolution.com
 * @LastEditTime: 2024-01-10 19:40:24
 * @FilePath: \ra-web-admin\src\views\monitor\monitorForm\vehicle\funcList\modules\baiduRtc\BaiduRtcTest.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div>
    <a-button @click="handleComeIn('therevideo', '0')">进入房间1</a-button>
    <a-button @click="handleComeIn('therevideo2', '1')">进入房间2</a-button>
    <a-button @click="handleComeIn('therevideo3', '2')">进入房间3</a-button>
    <div style="display: flex;">
      <div style="flex: 1" id="herevideo"></div>
      <div style="flex: 1" id="therevideo"></div>
      <div style="flex: 1" id="therevideo2"></div>
      <div style="flex: 1" id="therevideo3"></div>
    </div>
  </div>
</template>
<script>
import RtcHelper from './BRtcHelper'
export default {
  mixins: [RtcHelper],
  data() {
    return {
      brtc: {
        roomName: 'test02',
        appId: 'apppgimhuxdztex',
        userTokens: [
          {
            userId: 100,
            token: '00492a00fbc6f3b251a8a9e2348e6e1dab86b8e2b1e170488188519397f0a1705486685'
          },
          {
            userId: 103,
            token: '0049ea37ad8497372fb254cf70fa3199567496d506f17048818854a1fa0e41705486685'
          },
          {
            userId: 104,
            token: '004cd95e89dcba20d58bf7e8cc8587630d8386caeb517048818857ede916a1705486685'
          },
          {
            userId: 107,
            token: '004e907e576080b4caa50d12b8ac17f6c002b524c0717048818859ca75c1d1705486685'
          },
          {
            userId: 108,
            token: '004d4b6ae1d60b102e8a99e7e9bdf6ddb9c0fb54cae1704881885833365301705486685'
          },
          {
            userId: 111,
            token: '0043fe2e9e80c9c8dea6a9831524cd71d1ffa0fa6c217048818858fe8a4871705486685'
          },
          {
            userId: 114,
            token: '0047b61c55829d1040521bfbfdde3a969ecdb51239d1704881885773a12f31705486685'
          }
        ]
      },
      bindaccountinfo: {
        rtmp_comm: {
          push_head: 'vedio-steam.revolution.auto',
          pull_head: 'vedio1.revolution.auto'
        },
        video: [
          {
            rtmp: {
              auth_key: '1704910685-0-0-017215fe510a2b164ab7196febf6b2b6'
            },
            camera_type: 0
          },
          {
            rtmp: {
              auth_key: '1704910685-0-0-b14ee25004d54e76b7f3704baeec952c'
            },
            camera_type: 3
          },
          {
            rtmp: {
              auth_key: '1704910685-0-0-e0d0ba7478c536941392a79913c978e5'
            },
            camera_type: 4
          },
          {
            rtmp: {
              auth_key: '1704910685-0-0-d427df1a9a9c0c0c53e47ca55ace40b4'
            },
            camera_type: 7
          },
          {
            rtmp: {
              auth_key: '1704910685-0-0-e93edf811b583ed0a0e9e5c7e18776a8'
            },
            camera_type: 8
          },
          {
            rtmp: {
              auth_key: '1704910685-0-0-4839a85dcbc46077b74350eae3dbbaf9'
            },
            camera_type: 11
          },
          {
            rtmp: {
              auth_key: '1704910685-0-0-c445f7f7cd1974edfd2c47f4cd689abf'
            },
            camera_type: 14
          }
        ]
      }
    }
  },
  mounted() {},
  methods: {
    handleComeIn(id, index) {
      this.start(id, index)
    }
  }
}
</script>
