var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "a-button",
        {
          on: {
            click: function ($event) {
              return _vm.handleComeIn("therevideo", "0")
            },
          },
        },
        [_vm._v("进入房间1")]
      ),
      _c(
        "a-button",
        {
          on: {
            click: function ($event) {
              return _vm.handleComeIn("therevideo2", "1")
            },
          },
        },
        [_vm._v("进入房间2")]
      ),
      _c(
        "a-button",
        {
          on: {
            click: function ($event) {
              return _vm.handleComeIn("therevideo3", "2")
            },
          },
        },
        [_vm._v("进入房间3")]
      ),
      _vm._m(0),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticStyle: { display: "flex" } }, [
      _c("div", { staticStyle: { flex: "1" }, attrs: { id: "herevideo" } }),
      _c("div", { staticStyle: { flex: "1" }, attrs: { id: "therevideo" } }),
      _c("div", { staticStyle: { flex: "1" }, attrs: { id: "therevideo2" } }),
      _c("div", { staticStyle: { flex: "1" }, attrs: { id: "therevideo3" } }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }